import { createStore } from 'vuex';

export default createStore({
  state: {
    selected_map_title: '',
    menuOpen: true,
  },
  getters: {},
  mutations: {
    changeMapTitle(state, payload) {
      state.selected_map_title = payload.title;
      state.menuOpen = false;
    },
    toggleMenu(state) {
      state.menuOpen = !state.menuOpen;
    },
  },
  actions: {},
  modules: {},
});
