<script setup>
import { useStore } from 'vuex';

const store = useStore();

const onToggleCollapse = (arg) => {
  store.commit('toggleMenu');
};
</script>
<template>
  <div class="maps-header flex flex-row justify-center place-items-center bg-[#7F0404]">
    <button @click="onToggleCollapse" class="absolute left-[20px]">
      <svg viewBox="0 0 100 80" width="30" height="30" fill="white">
        <rect width="100" height="15" rx="10"></rect>
        <rect y="30" width="100" height="15" rx="10"></rect>
        <rect y="60" width="100" height="15" rx="10"></rect>
      </svg>
    </button>

    <p class="sm:text-sm md:text-2xl font-bold text-white">
      {{ store.state.selected_map_title }}
    </p>
  </div>
</template>
<style>
.maps-header {
  /* background-color: green; */
  background-color: white;
  min-height: 4rem;
  max-height: 4rem;
}
</style>
