<template>
  <button
    :class="`flex bg-white px-4 py-2 rounded-3xl shadow-md text-red-900 bg-gradient-to-b from-gray-200 border-2 border-white active:bg-gray-100 hover:bg-gray-200 ${
      this.class || ''
    }`"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: ['class'],
  name: 'MapButton',
};
</script>

<style></style>
