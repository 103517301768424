<script>
import ResizebleScreen from '@/components/ResizebleScreen';

export default {
  components: { ResizebleScreen },
};
</script>

<template>
  <ResizebleScreen>
    <router-view
      class="bg-gradient-to-r from-red-100 via-red-200 to-red-300 min-w-max w-screen min-h-full h-fit pt-10 pb-5 px-10"
    />
  </ResizebleScreen>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
