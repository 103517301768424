<script>
import MenuLateral from '@/layouts/MenuLateral';
import MapsHeader from '@/layouts/MapsHeader';
import { computed } from 'vue';
import Heart from './heart.json';
import { Vue3Lottie } from 'vue3-lottie';
export default {
  components: { MenuLateral, MapsHeader, Vue3Lottie },
  data() {
    return {
      Heart,
      isAnimating: false,
      change: false,
      scale: 1,
      panning: false,
      position: {
        x: 0,
        y: 0,
      },
      start: {
        x: 0,
        y: 0,
      },
    };
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      change: computed(() => this.change),
    };
  },
  computed: {
    transform() {
      return `translate(${this.position.x}px, ${this.position.y}px) scale(${this.scale})`;
    },
  },
  mounted: function () {
    this.setViewHeight();
    window.addEventListener('resize', () => {
      this.setViewHeight();
    });
    setTimeout(() => {
      this.playAnimation();
    }, 1000);
  },
  methods: {
    openForm() {
      window.open('https://forms.gle/M1rE6edV7PURadfp9', '_blank').focus();
    },
    playAnimation() {
      this.$refs.animation.play();
      setTimeout(() => {
        this.$refs.animation.stop();
        this.playAnimation();
      }, 10000);
    },
    setViewHeight: function () {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    mouseDown(e) {
      e.preventDefault();
      this.panning = true;
      this.start.x = e.clientX - this.position.x;
      this.start.y = e.clientY - this.position.y;
      this.change = !this.change;
    },

    mouseUp(e) {
      e.preventDefault();
      this.panning = false;
      this.change = !this.change;
    },
    mouseMove(e) {
      console.log();
      e.preventDefault();
      if (this.panning) {
        const deltaX = e.clientX - this.start.x;
        const deltaY = e.clientY - this.start.y;

        // if (deltaX > 1000 * this.scale || deltaY > 400 * this.scale) return;
        // if (deltaX < -500 * this.scale || deltaY < -400 * this.scale) return;

        this.position.x = deltaX;
        this.position.y = deltaY;

        this.change = !this.change;
        // this.setTransorm();
      }
    },
    onwheel(e) {
      e.preventDefault();
      const xs = (e.clientX - this.position.x) / this.scale;
      const ys = (e.clientY - this.position.y) / this.scale;
      const delta = e.wheelDelta ? e.wheelDelta : -e.deltaY;
      if (this.scale < 0.7 && delta < 0) return;
      if (this.scale > 3 && delta > 0) return;
      // eslint-disable-next-line no-undef, no-unused-expressions
      delta > 0 ? (this.scale *= 1.2) : (this.scale /= 1.2);

      this.position.x = e.clientX - xs * this.scale;
      this.position.y = e.clientY - ys * this.scale;

      // this.change = !this.change;
    },
  },
};
</script>

<template>
  <MenuLateral class="fixed flex z-40" />
  <div class="fixed right-10 bottom-20 z-40 w-[50px] md:w-[100px]">
    <Vue3Lottie ref="animation" :animationData="Heart" :autoPlay="false" :loop="false" />
  </div>
  <div
    class="fixed flex right-0 bottom-0 z-40 w-[150px] md:w-[200px] cursor-pointer"
    @click="openForm"
  >
    <img src="@/assets/hemacia.png" alt="" />
  </div>
  <lottie-animation />
  <div class="h-screen">
    <MapsHeader class="fixed w-screen flex flex-row-reverse z-40" />
    <div
      class="w-screen h-full pt-4"
      ref="zoom"
      :style="{ transform: transform }"
      @mousewheel="onwheel"
      @mousedown="mouseDown"
      @mousemove="mouseMove"
      @mouseup="mouseUp"
    >
      <slot />
    </div>
  </div>
</template>
