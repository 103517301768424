<script>
export default {
  props: {
    class: { type: String, default: 'text-red-900' },
    bg_color: String,
    title: String,
    title_class: String,
    body_class: { type: String, default: 'bg-gradient-to-b from-gray-200' },
  },
  name: 'MapButton',
};
</script>
<template>
  <div :class="`${this.class || ''} `">
    <div v-if="this.title" class="left-0 right-0 text-white flex justify-center">
      <h1
        :class="`bg-red-900 px-6 rounded-tr-3xl rounded-tl-3xl font-bold text-center ${
          this.title_class || ''
        }`"
      >
        {{ this.title }}
      </h1>
    </div>
    <div
      :class="` ${
        this.bg_color || 'bg-white text-red-900'
      }  rounded-xl relative px-6 py-4 text-left shadow-md border-2 border-white hover:bg-gray-100 ${
        this.body_class
      }`"
    >
      <slot />
    </div>
  </div>
</template>
