<script setup>
import { XMarkIcon } from "@heroicons/vue/24/solid";
import { ref } from "vue";
const visible = ref(false)
const ToogleVisibility = () => {
    visible.value = !visible.value;
}
const closeModal = () => {
    visible.value = false;
}
</script>
<template>
    <!-- Modal toggle -->
    <div @click="ToogleVisibility()">
        <slot name="button" />
    </div>

    <!-- Main modal -->
    <Teleport to="body">
        <div v-if="visible" class="flex place-content-center fixed w-screen h-screen top-0 left-0 bg-gray-500/50">
            <div class="flex flex-col place-self-center bg-white max-h-full min-w-[25%] modal-component rounded-lg">
                <div class="w-full flex flex-row place-content-end">
                    <button class="close-button" @click="closeModal()">
                        <div
                            class="rounded-full overflow-hidden w-7 mx-2 my-2 self-center bg-white text-[#7f0404] hover:bg-[#7f0404] hover:text-white">
                            <XMarkIcon class="stroke-[#7f0404] hover:stroke-white" />
                        </div>
                    </button>
                </div>
                <!-- Modal Body -->
                <slot name="body" />
            </div>
        </div>
    </Teleport>
</template>

<style>
.modal-component {
    max-height: 85vh;
}
</style>