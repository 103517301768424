<script setup>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import MapButton from '@/components/Button/MapButton.vue';
import MapContainer from '@/components/MapContainer/MapContainer.vue';

const store = useStore();

onMounted(() => {
  store.commit({ type: 'changeMapTitle', title: 'Mapa Hemoglobinopatias' });
});
</script>

<template>
  <div class="flex h-[1250px] w-[2100px] pl-[200px] pt-[100px]">
    <div class="flex relative">
      <div class="absolute top-[50px] left-[50px] flex flex-col">
        <div class="flex items-center">
          <svg class="absolute w-[600px] h-[500px] z-0 top-[-30px]">
            <line x1="40" y1="165" x2="500" y2="165" stroke="black" />
            <line x1="150" y1="80" x2="150" y2="310" stroke="black" />
          </svg>
          <MapContainer
            :class="'text-[8px] mb-[90px] mt-[30px] top-[50px] p-1 max-w-[300px] z-10'"
            title="Anemia Falciforme"
          >
            <ul>
              <li>- Condição hereditária autossómica recessiva;</li>
              <li>- Hemoglobina (HbSS) homozigose;</li>
              <li>- Hemoglobina produzida funciona inadequadamente;</li>
              <li>- Hemácias em forma de foice são mais frágeis, se tornam rígidas e rompem-se;</li>
              <li>- Evento hemolítico causa anemia.</li>
            </ul>
          </MapContainer>

          <div class="z-10">
            <MapButton :class="'max-w-[100px] text-[10px] h-[25px] py-1 font-bold mt-6 ml-6'"
              >Estruturais</MapButton
            >
          </div>
        </div>

        <div class="flex w-[600px] justify-around mb-4">
          <svg class="absolute w-[600px] h-[700px] z-0 top-[-30px]">
            <line x1="90" y1="310" x2="500" y2="310" stroke="black" />
            <line x1="100" y1="310" x2="100" y2="350" stroke="black" />
            <line x1="300" y1="310" x2="300" y2="500" stroke="black" />
            <line x1="330" y1="500" x2="330" y2="700" stroke="black" />
            <line x1="500" y1="310" x2="500" y2="350" stroke="black" />
          </svg>
          <div class="z-10">
            <MapButton :class="'max-w-[100px] text-[10px] h-[25px] py-1 font-bold mt-6 ml-4'"
              >Causa</MapButton
            >
          </div>
          <div class="z-10">
            <MapButton :class="'max-w-[100px] text-[10px] h-[25px] py-1 font-bold mt-6 ml-8'"
              >Apresenta</MapButton
            >
          </div>
          <div class="z-10">
            <MapButton :class="'max-w-[100px] text-[10px] h-[25px] py-1 font-bold mt-6 ml-6 mr-4'"
              >Principais</MapButton
            >
          </div>
        </div>

        <div class="flex w-[600px] justify-between">
          <svg class="absolute w-[600px] h-[700px] z-0 top-[200]">
            <line x1="75" y1="300" x2="75" y2="520" stroke="black" />

            <line x1="50" y1="500" x2="140" y2="500" stroke="black" />
            <line x1="140" y1="440" x2="140" y2="560" stroke="black" />
            <line x1="140" y1="440" x2="360" y2="440" stroke="black" />
            <line x1="140" y1="560" x2="250" y2="560" stroke="black" />
            <!-- <line x1="500" y1="310" x2="500" y2="350" stroke="black" /> -->
          </svg>
          <div>
            <MapContainer :class="'text-[8px] p-1 max-w-[200px] z-10'" title="Etiologia">
              <ul>
                <li>- Disturbio genético hereditário;</li>
                <li>- Mutação no cromossomo 11;</li>
                <li>- Substituição do aminoácido ac.glutâmico pela valina;</li>
                <li>- Alteração estrutural da hemoglobina.</li>
              </ul>
            </MapContainer>
            <MapContainer
              :class="'text-[8px] p-1 max-w-[200px] z-10 flex flex-col mt-[50px]'"
              title="Achados laboratoriais"
            >
              <ul>
                <li>- Hemograma;</li>
                <li>- VCM normal ou aumentado RDW aumentado ou normal; - HCM e CHCM normais;</li>
                <li>- Reticulócitos aumentado;</li>
                <li>- Plaquetas aumentada;</li>
                <li>- Leucócitos diminuído ou aumentado;</li>
                <li>
                  - Poiquilocitose: Drepanócitos, esferócitos corpúsculo de Howell Jolly, codócitos;
                </li>
                <li>- Policromasia;</li>
                <li>- Eritroblastos.</li>
              </ul>
            </MapContainer>
            <div class="mb-8 z-10 flex">
              <MapButton
                :class="'max-w-[100px] text-[10px] h-[25px] py-1 font-bold mt-6 ml-6 mr-4 z-10'"
                >Observamos</MapButton
              >
            </div>
            <div class="flex absolute">
              <div class="w-[100px] ml-[25px] mt-[10px] z-10">
                <img src="@/assets/mapa-anemias-carenciais/microscopio.jpg" alt="" />
              </div>
              <div class="mt-[-70px] ml-[35px]">
                <div class="flex items-center">
                  <!-- <span class="  h-[100px] bg-gray-100 z-10"> lamina 1</span> -->
                  <div class="mt-6 w-[50px] ml-[10px] mt-[30px] z-10">
                    <img src="@/assets/lamina-verm.png" alt="" />
                  </div>
                  <div class="ml-6">
                    <MapContainer
                      :body_class="'text-white text-[10px] px-2 py-1 rounded-md text-center'"
                      :class="'max-w-[80px]'"
                      :bg_color="'bg-red-800 hover:bg-red-400'"
                      title_class="p-0"
                    >
                      Inclusões poiquilocitose policromasia
                    </MapContainer>
                  </div>
                  <div class="w-[150px] absolute top-[-40px] left-[350px]">
                    <img src="@/assets/mapa-hemoglobinopatias/inclusoes.jpeg" alt="" />
                  </div>
                </div>
                <div class="flex items-center">
                  <div class="mt-6 w-[50px] ml-[10px] mt-[40px] z-10">
                    <img src="@/assets/lamina-azul.png" alt="" />
                  </div>
                  <div class="ml-6">
                    <MapContainer
                      :body_class="'text-white text-[10px] px-2 py-1 rounded-md text-center'"
                      :class="'max-w-[80px]'"
                      :bg_color="'bg-red-800 hover:bg-red-400'"
                      title_class="p-0"
                    >
                      Reticulócitos aumentados
                    </MapContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MapContainer
            :body_class="'px-0 py-0 rounded-none'"
            :class="'text-[8px] p-1 max-w-[200px] p-1 z-10'"
            title="Fisiopatologia"
          >
            <div class="w-[140px] z-10">
              <img src="@/assets/mapa-hemoglobinopatias/fisiopatologia.png" alt="" />
            </div>
          </MapContainer>
          <MapContainer :class="'text-[8px] p-1 max-w-[200px] z-10'" title="Achados clínicos">
            <ul>
              <li>- Condição pode atingir multiplos orgãos.</li>
              <li>- Palidez, ictericia, fadiga, anemia;</li>
              <li>- SNC, acidente isquemico, AVC;</li>
              <li>- Osteomielite, osteoporose dores asteorticulares;</li>
              <li>- Esplenomegalia (Baço);</li>
              <li>- Cardiomegalia, insuficiência cardiaca (coração)</li>
            </ul>
          </MapContainer>
        </div>

        <div class="flex flex-col absolute top-[480px] left-[260px]">
          <svg class="absolute w-[600px] h-[700px] z-0 left-[-65px]">
            <line x1="0" y1="85" x2="120" y2="85" stroke="black" />
            <line x1="120" y1="85" x2="120" y2="150" stroke="black" />

            <line x1="150" y1="85" x2="500" y2="85" stroke="black" />
            <line x1="150" y1="85" x2="150" y2="150" stroke="black" />
          </svg>
          <div>
            <div class="z-10 flex">
              <MapButton
                :class="'max-w-[100px] text-[10px] h-[25px] py-1 font-bold mt-6 ml-6 mr-4 z-10'"
                >Para realizar</MapButton
              >
            </div>
          </div>
          <div class="flex ml-[-70px]">
            <div class="z-10 flex">
              <MapButton
                :class="'max-w-[100px] text-[10px] h-[25px] py-1 font-bold mt-6 ml-6 mr-4 z-10'"
                >Observamos</MapButton
              >
            </div>
            <div class="z-10 flex ml-[110px]">
              <MapButton
                :class="'max-w-[130px] text-[10px] h-[25px] py-1 font-bold mt-6 ml-6 mr-4 z-10'"
                >Orientação médica</MapButton
              >
            </div>
          </div>
          <MapContainer :class="'text-[8px] p-1 max-w-[500px] z-10'" title="Diagnóstico">
            <ul>
              <li>- Avaliação do histórico familiar;</li>
              <li>- Hemograma (completo);</li>
              <li>- Avaliar presença de drepanócitos na hematoscopia;</li>
              <li>- Exame pré-natal (teste do pezinho);</li>
              <li>- Eletroforese de hemoglobina (HbSS);</li>
              <li>- Contagem de reticulócitos (aumentado);</li>
              <li>- Bioquímica: LDH, Bilirrubina (aumentado);</li>
            </ul>
          </MapContainer>
        </div>

        <div class="flex flex-col absolute top-[-25px] left-[430px]">
          <svg class="absolute w-[600px] h-[700px] z-0 top-[200]">
            <line x1="30" y1="166" x2="300" y2="160" stroke="black" />
            <line x1="100" y1="50" x2="100" y2="200" stroke="black" />
            <line x1="300" y1="45" x2="300" y2="160" stroke="black" />
            <line x1="300" y1="45" x2="450" y2="45" stroke="black" />
          </svg>

          <div class="z-10 flex">
            <MapButton :class="'text-xl  py-1 font-bold mt-6 mr-4 z-10'"
              >Hemoglobinopatias</MapButton
            >
          </div>
          <div class="flex mt-[60px]">
            <MapContainer :class="'text-[8px] p-1 w-[230px] z-10'">
              <ul>
                <li>- Disturbio de origem genérica;</li>
                <li>- Mutações altera a síntese de hemoglobina;</li>
                <li>- Divididas em estruturais e de produção.</li>
              </ul>
            </MapContainer>
            <div class="z-10 flex">
              <MapButton
                :class="'max-w-[100px] text-[10px] h-[25px] py-1 font-bold mt-6 ml-6 mr-4 z-10'"
                >Produção</MapButton
              >
            </div>
          </div>
        </div>

        <div class="flex flex-col absolute top-[-25px] left-[800px]">
          <svg class="absolute w-[600px] h-[700px] z-0 top-[200]">
            <line x1="100" y1="50" x2="100" y2="200" stroke="black" />
            <line x1="50" y1="200" x2="170" y2="200" stroke="black" />
            <line x1="50" y1="200" x2="50" y2="540" stroke="black" />
            <line x1="170" y1="200" x2="170" y2="540" stroke="black" />
          </svg>

          <div class="z-10 flex">
            <MapButton :class="'text-xl  py-1 font-bold mt-6 ml-[40px] z-10'">Talassemia</MapButton>
          </div>
          <div class="flex flex-col mt-[30px]">
            <MapContainer :class="'text-[8px] p-1 w-[230px] z-10 mb-6'">
              <ul>
                São um grupo de doenças hereditárias causada pela ausência ou redução a produção das
                quatros cadeias de aminoácidos que forma a hemoglobina.
              </ul>
            </MapContainer>

            <div class="flex justify-around">
              <div>
                <MapContainer
                  :body_class="'text-white text-[10px] px-2 py-1 rounded-md text-center'"
                  :class="'max-w-[100px] mb-2'"
                  :bg_color="'bg-red-800 hover:bg-red-400'"
                  title_class="p-0"
                >
                  Alfa-talassemia
                </MapContainer>
                <MapContainer
                  :class="'text-[8px] p-1 w-[100px] z-10 mb-6'"
                  :body_class="'px-3 py-1'"
                >
                  <ul>
                    Deleção de um ou mais gene alfa
                  </ul>
                </MapContainer>
                <MapContainer
                  :body_class="'text-white text-[10px] px-2 py-1 rounded-md text-center'"
                  :class="'max-w-[100px] mb-2'"
                  :bg_color="'bg-red-800 hover:bg-red-400'"
                  title_class="p-0"
                >
                  Formas
                </MapContainer>
              </div>
              <div>
                <MapContainer
                  :body_class="'text-white text-[10px] px-2 py-1 rounded-md text-center'"
                  :class="'max-w-[100px] mb-2'"
                  :bg_color="'bg-red-800 hover:bg-red-400'"
                  title_class="p-0"
                >
                  Beta-talassemia
                </MapContainer>
                <MapContainer
                  :class="'text-[8px] p-1 w-[100px] z-10 mb-2'"
                  :body_class="'px-2 py-1'"
                >
                  <ul>
                    Presença de genes defeituosos que produzem ou não cadeias betas.
                  </ul>
                </MapContainer>
                <MapContainer
                  :body_class="'text-white text-[10px] px-2 py-1 rounded-md text-center'"
                  :class="'max-w-[100px] mb-2'"
                  :bg_color="'bg-red-800 hover:bg-red-400'"
                  title_class="p-0"
                >
                  Formas
                </MapContainer>
              </div>
              <div class="flex absolute top-[375px] left-[-130px] z-10">
                <div class="w-[320px] z-10">
                  <img src="@/assets/mapa-hemoglobinopatias/talassemia.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col absolute top-[510px] left-[620px]">
          <svg class="absolute w-[600px] h-[700px] z-0 top-[200]">
            <line x1="90" y1="160" x2="250" y2="160" stroke="black" />
            <line x1="90" y1="160" x2="90" y2="200" stroke="black" />
          </svg>

          <div class="flex flex-col mt-[30px]">
            <MapContainer
              :class="'text-[8px] p-1 w-[180px] z-10 mb-6'"
              :body_class="'px-2 py-2'"
              title="Tratamento"
            >
              <ul>
                <li>- Esplenectomia;</li>
                <li>- Transfusões;</li>
                <li>- Medicamento (HYDROXYUREIA);</li>
                <li>- Transplante de médula óssea e células estaminais.</li>
              </ul>
            </MapContainer>
            <MapContainer
              :class="'text-[8px] p-1 w-[180px] z-10 mb-6'"
              :body_class="'px-2 py-2'"
              title="Fisiopatologia"
            >
              <ul>
                <li>- Desequilibrio na síntese de hemoglobina nas cadeias Alfa e Beta;</li>
                <li>-Precipitação de excesso de cadeia não pareadas;</li>

                <li>
                  - Alfa-talassemia excesso de Gama e Beta tetrâmeros instáveis de hemoglobina e
                  hemoglobina de Bart (y4);
                </li>
                <li>- Precipitação e alteração na membrana da hemácia levando a destruição;</li>

                <li>
                  - Beta-talassemia excesso de cadeias Alfas, formação de precipitados, alteração
                  das hemácias e consequente destruição.
                </li>
              </ul>
            </MapContainer>
          </div>
        </div>

        <div class="flex flex-col absolute top-[550px] left-[820px]">
          <svg class="absolute w-[600px] h-[700px] z-0 top-[-30px]">
            <line x1="40" y1="0" x2="40" y2="50" stroke="black" />
            <line x1="50" y1="43" x2="100" y2="43" stroke="black" />
            <line x1="150" y1="30" x2="150" y2="100" stroke="black" />
            <line x1="40" y1="100" x2="40" y2="150" stroke="black" />
          </svg>

          <div class="flex items-center mb-2">
            <div class="z-10 flex mr-4">
              <MapButton :class="'max-w-[100px] text-[10px] h-[25px] py-1 font-bold  z-10'"
                >Causa</MapButton
              >
            </div>
            <MapContainer
              :body_class="'text-white text-[10px] px-2 py-1 rounded-md text-center'"
              :class="'w-[130px]'"
              :bg_color="'bg-red-800 hover:bg-red-400'"
            >
              Mutações e alterações
            </MapContainer>
          </div>
          <MapContainer :class="'text-[8px] p-1 max-w-[200px] z-10 mb-2'">
            <ul>
              <li>Cromossomo 16 (Alfa-talassemia)</li>
              <li>Cromossomo 11 (Beta-talassemia)</li>
            </ul>
          </MapContainer>
          <div class="z-10 flex mr-4">
            <MapButton :class="'max-w-[100px] text-[10px] h-[25px] py-1 font-bold  z-10'"
              >Apresenta</MapButton
            >
          </div>
        </div>

        <div class="flex absolute top-[700px] left-[820px]">
          <svg class="absolute w-[600px] h-[700px] z-0 top-[-30px] left-[-30px]">
            <line x1="0" y1="95" x2="200" y2="95" stroke="black" />
            <line x1="230" y1="95" x2="230" y2="220" stroke="black" />
            <line x1="220" y1="215" x2="440" y2="215" stroke="black" />
            <line x1="440" y1="50" x2="440" y2="215" stroke="black" />
          </svg>
          <div class="z-10 flex mr-4">
            <MapButton :class="'w-[100px] text-[10px] h-[25px] py-1 font-bold  z-10 mt-[50px]'"
              >Para realizar</MapButton
            >
          </div>
          <div class="flex flex-col items-center">
            <MapContainer :class="'text-[8px] p-1 w-[200px] z-10'" title="Diagnóstico">
              <ul>
                <li>- História clínica (paciente) e familiar;</li>
                <li>- Hemograma;</li>
                <li>- Eletroforese de hemoglobina</li>
                <li>- Análise da morfologia eritrocitária;</li>
                <li>- Teste resistência globular osmótica;</li>
                <li>- HPLC espectometria, densitometria e utilização de microcolunas.</li>
              </ul>
            </MapContainer>
            <div class="z-10 flex mr-4">
              <MapButton :class="'w-[100px] text-[10px] h-[25px] py-1 font-bold  z-10 mt-[30px]'"
                >Observamos</MapButton
              >
            </div>
          </div>
        </div>

        <div class="flex flex-col absolute top-[0px] left-[1120px] items-center">
          <svg class="absolute w-[600px] h-[700px] z-0 top-[-30px] left-[-120px]">
            <line x1="0" y1="650" x2="100" y2="650" stroke="black" />
            <line x1="100" y1="455" x2="100" y2="650" stroke="black" />
            <line x1="100" y1="455" x2="230" y2="455" stroke="black" />
            <line x1="260" y1="200" x2="260" y2="455" stroke="black" />

            <line x1="260" y1="500" x2="260" y2="700" stroke="black" />
          </svg>
          <MapContainer :class="'text-[10px] p-1 w-[300px] z-10'" title="Achados clinicos">
            <p class="mb-2">Alfa talassemia</p>
            <ul>
              <li>- Portador silencioso (sem manifestação clínica)</li>
              <li>- Traço talassemico (anemia leve)</li>
              <li>-Doença da hemoglobiona H (anemia moderada)</li>
              <li>
                - Hidropsia fetal hemoglobina de Barts (anemia grave imcompatível com a vida). Podem
                apresentar esplenomegalia, icterícia e colelitíase.
              </li>
            </ul>
            <p class="my-2">Beta-talassemia</p>
            <ul>
              <li>
                - Beta-talassemia Major (anemia grave) palidez, icterícia, diminuição da massa
                muscular, hepatoesplenomegália, atraso de crescimento e maturação sexual entre
                outros pacientes não tratados morrem na primeira ou segunda década de vida.
              </li>
              <li>
                - Beta-talassemia intermediária (anemia leve a grave) atraso no desenvolvimento e
                crescimento, a doentes assintomáticos até à idade adulta, manifestam anemia ligeira.
                Predisposição para desenvolver úlceras dos membros inferiores e eventos
                tromboembólicos.
              </li>
              <li>- Beta-talassemia menor/traço talassemico (anemia leve) assintomático.</li>
            </ul>
          </MapContainer>
          <div class="z-10 flex mr-4 mb-4">
            <MapButton :class="'max-w-[100px] text-[10px] h-[25px] py-1 font-bold  z-10 mt-4'">
              Principais</MapButton
            >
          </div>
          <MapContainer :class="'text-[8px] p-1 w-[240px] z-10'" title="Tratamento">
            <ul>
              <li>- Transfusões (anemia grave)</li>
              <li>- Transplante de células progenitoras hematopoieticas</li>
              <li>- Esplenectomia (depende da gravidade da hemolíse e da esplenomegalia)</li>
              <li>- Reposição de ac. fólico e quelação de ferro.</li>
            </ul>
          </MapContainer>
          <div class="z-10 flex mr-4 mb-4">
            <MapButton :class="'max-w-[200px] text-[10px] h-[25px] py-1 font-bold  z-10 mt-4'">
              Orientação médica</MapButton
            >
          </div>
          <MapContainer :class="'text-[8px] p-1 w-[240px] z-10'" title="Achados laboratoriais">
            <ul>
              <li>- Hemograma;</li>
              <li>
                - Anemia (leve a grave) depende do tipo e forma emq ue apresenta a talassemia.
              </li>
              <li>- VCM (Microcitose)</li>
              <li>- RDW (Anisocitose presente)</li>
              <li>- Policromasia</li>
              <li>- Poliquilocitose: Dacriócitos, esquizócitos, codócitos;</li>
              <li>- Eritroblastos;</li>
              <li>- Inclusões: Pontilhado basófilo;</li>
              <li>- Reticulócitos (aumentado)</li>
              <li>- LDH e bilirrubina (aumentados)</li>
            </ul>
          </MapContainer>
        </div>

        <div class="flex flex-col absolute top-[400px] left-[1460px]">
          <svg class="absolute w-[600px] h-[700px] z-0 top-[-30px] left-[-120px]">
            <line x1="0" y1="390" x2="130" y2="390" stroke="black" />
            <line x1="160" y1="230" x2="160" y2="400" stroke="black" />
            <line x1="150" y1="230" x2="260" y2="230" stroke="black" />
            <line x1="150" y1="100" x2="150" y2="230" stroke="black" />
            <line x1="260" y1="100" x2="260" y2="230" stroke="black" />
          </svg>
          <div>
            <div class="flex ml-[-20px] items-end mb-4">
              <MapContainer
                :body_class="'text-white text-[10px] px-2 py-1 rounded-md text-center'"
                :class="'max-w-[100px] mr-4'"
                :bg_color="'bg-red-800 hover:bg-red-400'"
                title_class="p-0"
              >
                Hemácias microcíticas, Inclusões e Poiquilocitose
              </MapContainer>
              <MapContainer
                :body_class="'text-white text-[10px] px-2 py-1 rounded-md text-center'"
                :class="'max-w-[100px]'"
                :bg_color="'bg-red-800 hover:bg-red-400'"
                title_class="p-0"
                >Reticulócitos aumentados
              </MapContainer>
            </div>
            <div class="flex">
              <!-- <span class=" w-[60px] h-[100px] bg-gray-100 z-10"> lamina 1</span> -->
              <div class="mr-[50px] w-[55px] ml-[0px] mt-[30px] z-10">
                <img src="@/assets/lamina-verm.png" alt="" />
              </div>
              <div class="mr-[50px] w-[50px] ml-[10px] mt-[30px] z-10">
                <img src="@/assets/lamina-azul.png" alt="" />
              </div>
              <!-- <span class="w-[60px] h-[100px] bg-gray-100 z-10"> lamina 2</span> -->
            </div>
          </div>
          <div class="w-[100px] mt-6 z-10">
            <img src="@/assets/mapa-anemias-carenciais/microscopio.jpg" alt="" />
          </div>
          <div class="z-10 flex mb-4">
            <MapButton :class="'max-w-[100px] text-[10px] h-[25px] py-1 font-bold  z-10 mt-4'">
              Observamos</MapButton
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
