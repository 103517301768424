import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import MapaHemoglobinoPatias from '@/views/Mapas/MapaHemoglobinoPatias.vue';
import MapaAnemiasGerais from '@/views/Mapas/MapaAnemiasGerais.vue';
import MapaAnemiasHemoliticas from '@/views/Mapas/MapaAnemiasHemoliticas.vue';
import MapaAnemiasCarenciais from '@/views/Mapas/MapaAnemiasCarenciais.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/anemias',
  },
  {
    path: '/hemoglobinopatias',
    name: 'hemoglobinopatias',
    component: MapaHemoglobinoPatias,
  },
  {
    path: '/anemias',
    name: 'anemias-geral',
    component: MapaAnemiasGerais,
  },
  {
    path: '/anemias/hemoliticas',
    name: 'anemias-hemoliticas',
    component: MapaAnemiasHemoliticas,
  },
  {
    path: '/anemias/carenciais',
    name: 'anemias-carenciais',
    component: MapaAnemiasCarenciais,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
