<script setup>
import { onMounted, onUpdated, ref } from 'vue';
import { useStore } from 'vuex';

import MapButton from '@/components/Button/MapButton.vue';
import MapContainer from '@/components/MapContainer/MapContainer.vue';
import LargeModal from '@/components/Modal/LargeModal.vue';

const store = useStore();
const nodeConnections = [['node1', 'node2']];
const showNodeIds = ref(true);

onMounted(() => {
  store.commit({ type: 'changeMapTitle', title: 'Mapa Anemias Carenciais' });
});
</script>

<template>
  <div class="flex h-[1500px] w-[2200px] bg-red-100 pt-[100px] pl-[100px]">
    <div class="flex relative">
      <div class="ml-[50px] absolute top-[40px] left-[40px] flex h-fit relative">
        <svg class="absolute w-full h-screen z-0">
          <line x1="50" y1="35" x2="1200" y2="35" stroke="black" />
        </svg>
        <div class="flex z-10 items-center">
          <div class="mr-[130px] flex flex-col">
            <MapButton :class="'max-w-[200px]'">Distribuição de ferro no organismo</MapButton>
          </div>
          <div class="mr-[100px]">
            <MapButton :class="'max-w-[300px] text-2xl'">Anemia Ferropriva</MapButton>
          </div>
          <div>
            <MapButton :class="'max-w-[250px] font-bold mr-[120px] text-xl'"
              >Anemias Carenciais
            </MapButton>
          </div>
          <div>
            <MapButton :class="'max-w-[350px]'">Anemia Megaloblastica</MapButton>
          </div>
        </div>
      </div>
      <svg class="absolute w-[400px] z-0 top-[100px]">
        <line x1="150" y1="0" x2="150" y2="150" stroke="black" />
      </svg>
      <div class="absolute top-[150px] left-[50px]">
        <LargeModal>
          <template #button>
            <div class="w-[290px] z-10">
              <img src="@/assets/mapa-anemias-carenciais/dist-ferro.png" alt="" />
            </div>
          </template>
        </LargeModal>

        <div class="flex items-center relative">
          <svg class="absolute w-[400px] z-0 top-[61px]">
            <line x1="50" y1="35" x2="900" y2="35" stroke="black" />
          </svg>
          <div class="w-[200px] mt-6 flex flex-row z-10">
            <MapContainer :class="'text-[8px]'" title="Achados laboratoriais">
              <ul>
                <li>- Hemácias microcíticas (VCM e RDW elevado).</li>
                <li>Pontilhado basófilo</li>
                <li>Anel de Carbot</li>
                <li>
                  Citopenias:
                  <ul class="ml-2">
                    <li>- Neutropenia</li>
                    <li>- Plaquetopenia</li>
                  </ul>
                </li>
                <li>- Presença de neutrófilos hipersegmentados.</li>
                <li>- Reticulócitos diminuidos.</li>
              </ul>
            </MapContainer>
          </div>
          <div class="z-10">
            <MapButton :class="'max-w-[80px] text-[8px] h-[20px] py-1 font-bold mt-[5px] ml-6'"
              >Observamos</MapButton
            >
          </div>
        </div>
      </div>

      <svg class="absolute w-full h-[1200px] z-0">
        <line x1="150" y1="500" x2="150" y2="860" stroke="black" />
        <line x1="110" y1="760" x2="520" y2="760" stroke="black" />
        <line x1="120" y1="910" x2="850" y2="910" stroke="black" />
        <line x1="740" y1="750" x2="740" y2="900" stroke="black" />
      </svg>

      <div class="absolute top-[680px] left-[80px] flex">
        <div class="flex flex-col relative">
          <div class="w-[150px]">
            <img src="@/assets/mapa-anemias-carenciais/microscopio.jpg" alt="" />
          </div>

          <div class="flex relative">
            <div class="w-[60px] ml-[35px] mt-6">
              <img src="@/assets/lamina-verm.png" alt="" />
            </div>
            <div class="z-10 mr-6 absolute top-[70px] left-[150px]">
              <MapButton :class="' text-[10px] w-[150px] py-1 font-bold '"
                >Hemácias microcíticas hipocrômicas</MapButton
              >
            </div>
            <div class="-10 mr-6 absolute top-[20px] left-[330px] h-[150px] w-[150px]">
              <img src="@/assets/mapa-anemias-carenciais/poliquilocitose2.png" alt="" />
            </div>

            <div class="z-10 mr-6 absolute top-[75px] left-[610px]">
              <MapButton :class="'text-[10px] py-1 font-bold '">Poliquilocitose</MapButton>
            </div>
            <!-- <div class="w-[80px] ml-[40px] mt-6 z-10"></div> -->

            <MapContainer
              class="text-[8px] z-10 mr-6 absolute top-[30px] left-[760px] w-[150px]"
              :body_class="'px-1 mx-0 py-1 my-0'"
              :title="'Esquizócitos'"
            >
              <img src="@/assets/mapa-anemias-carenciais/esquizocitos.png" alt="" />
            </MapContainer>
          </div>
        </div>
        <div class="flex items-center h-[150px]">
          <div class="w-[60px] ml-4 mt-6">
            <img src="@/assets/lamina-azul.png" alt="" />
          </div>
          <div class="z-10 mx-6">
            <MapButton :class="' text-[10px] w-[150px] py-1 font-bold '"
              >Retículócitos diminuídos ou normais</MapButton
            >
          </div>
          <div class="w-[150px]">
            <img src="@/assets/mapa-anemias-carenciais/hemacias-microc.png" alt="" />
          </div>

          <MapContainer
            class="text-[8px] w-[150px] ml-6 mt-[43px]"
            :body_class="'px-1 mx-0 py-1 my-0'"
            :title="'Dacriócitos'"
          >
            <img src="@/assets/mapa-anemias-carenciais/dacriocitos.png" alt="" />
          </MapContainer>
        </div>
      </div>

      <div class="absolute top-[100px] left-[400px] flex">
        <svg class="absolute w-[400px] z-0 top-[0px]">
          <line x1="150" y1="0" x2="150" y2="40" stroke="black" />
          <line x1="50" y1="40" x2="250" y2="40" stroke="black" />
          <line x1="70" y1="40" x2="70" y2="80" stroke="black" />
          <line x1="260" y1="40" x2="260" y2="80" stroke="black" />
        </svg>
        <div class="z-10">
          <MapButton :class="'max-w-[100px] text-[10px] h-[25px] py-1 font-bold mt-6 ml-6'"
            >Apresenta</MapButton
          >
        </div>
        <div class="z-10">
          <MapButton :class="'max-w-[100px] text-[10px] h-[25px] py-1 font-bold mt-6 ml-[120px]'"
            >Causa</MapButton
          >
        </div>
      </div>

      <div class="absolute top-[110px] left-[820px] flex flex-col">
        <svg class="absolute w-[400px] h-[300px] z-0 top-[-30px]">
          <line x1="40" y1="0" x2="40" y2="260" stroke="black" />
        </svg>
        <div>
          <MapContainer
            :body_class="'text-white text-[10px] text-white px-1 py-1 rounded-md ml-[13px]'"
            :class="'w-[70px] mt-[10px]'"
            :bg_color="'bg-red-800 hover:bg-red-400'"
            title_class="p-0"
          >
            Definição
          </MapContainer>
        </div>
        <div class="w-[180px] ml-[-40px] mt-[10px]">
          <MapContainer :class="'text-[8px] p-1'">
            Grupo de alterações no qual, apesar de não apresentar doença especfífica há carência de
            elementos essenciais para produção de hemácias (ferro, folato ou vitamina B12)
          </MapContainer>
        </div>
        <div>
          <MapContainer
            :body_class="'text-white text-center text-[10px] text-white px-1 py-1 rounded-md mt-[10px]'"
            :class="'max-w-[90px]'"
            :bg_color="'bg-red-800 hover:bg-red-400'"
            title_class="p-0"
          >
            Se apresenta como
          </MapContainer>
        </div>
        <div class="w-[300px] ml-[-40px] mt-[10px]">
          <MapContainer :class="'text-[8px] p-1'">
            <ul>
              <li>
                <span class="text-red-900 font-bold">- Anemia Megaloblastica</span>: Deficiência de
                Ac. fólico e/ou vitamina B12.
              </li>
              <li>
                <span class="text-red-900 font-bold"> - Anemia Peniciosa</span>: Deficiência de
                Fator intrínseco necessário para absorção de votamina B12.
              </li>
              <li>
                <span class="text-red-900 font-bold">- Anemia Ferropriva</span>: Deficiência de
                ferro.
              </li>
            </ul>
          </MapContainer>
        </div>
      </div>

      <div class="absolute top-[90px] left-[1050px] flex">
        <svg class="absolute w-[460px] z-0 top-[0px]">
          <line x1="150" y1="0" x2="150" y2="40" stroke="black" />
          <line x1="50" y1="40" x2="450" y2="40" stroke="black" />
          <line x1="70" y1="40" x2="70" y2="80" stroke="black" />
          <line x1="450" y1="40" x2="450" y2="80" stroke="black" />
          <line x1="250" y1="40" x2="250" y2="80" stroke="black" />
        </svg>
        <div class="z-10">
          <div class="z-10">
            <MapButton :class="'max-w-[100px] text-[10px] h-[25px] py-1 font-bold mt-6 ml-6'"
              >Apresenta</MapButton
            >
          </div>
          <div class="ml-[-30px] w-[200px] mt-[10px]">
            <MapContainer :class="'text-[8px]'" title="Fisiopatologia">
              <ul>
                <li>
                  - Deficiência na síntese de DNA causada pela carência de B12 e folato e/ou
                  Deficiência fator intríseco;
                </li>
                <li>- Síntese de RNA intacta;</li>
                <li>
                  - Diminuição na síntese de hemoglobina; - Atraso na divisão celular, aumento do
                  tamanho celular.
                </li>
              </ul>
            </MapContainer>
          </div>
        </div>
        <div class="z-10">
          <div>
            <MapButton :class="'max-w-[100px] text-[10px] h-[25px] py-1 font-bold mt-6 ml-[50px]'"
              >Causa</MapButton
            >
          </div>
          <div class="flex">
            <div class="ml-[10px] w-[150px] mt-[10px]">
              <MapContainer :class="'text-[8px]'" title="Etiologia">
                - Absorção deficiente de nutrientes; transporte ineficaz de vitaminas e deficiências
                do metabolismo
              </MapContainer>
            </div>
            <div class="ml-[10px] w-[250px] mt-[10px]">
              <MapContainer :class="'text-[8px]'" title="Achados clínicos">
                <ul>
                  <li class="text-red-900 font-bold">Def de B12</li>
                  <li>- Irritabilidade, cafeleia, fraqueza.</li>
                  <li>
                    <span class="text-red-900 font-bold">- Manifestações digestivas</span>:
                    Diarreia, perda ponderal e outas.
                  </li>
                  <li>
                    <span class="text-red-900 font-bold">- Manifestações neurológicas</span>
                    :Parestesias nas extremidades desequilíbrio marcha atáxica, demanda psicose
                    entre outras.
                  </li>
                  <li class="text-red-900 font-bold">Def. de folato</li>
                  <li>
                    - Manifestações semelhantes exceto por 2 fatores:
                    <ul class="ml-2">
                      <li>- Não apresenta manifestações neurológicas;</li>
                      <li>- Manifestações digestivas exuberantes.</li>
                    </ul>
                  </li>
                </ul>
              </MapContainer>
            </div>
          </div>
        </div>
      </div>

      <div class="absolute top-[170px] z-10 left-[370px] flex">
        <div class="w-[200px]">
          <MapContainer :class="'text-[8px] p-1'" title="Fisiopatologia">
            <ul>
              <li>
                -O heme é sintetizado por uma via catalizada por 8 enzimas o final dessa via
                consiste na inserção do ferro no anel de prortoporfirina;
              </li>
              <li>
                - Da mitocôndria um transportar exporta o heme que é incorporado às cadeias de
                globina para formar a hemoglobina;
              </li>
              <li>
                - Carência de ferro, o heme e a HB não são produzidos consequente- mente instala-se
                anemia ferropriva
              </li>
            </ul>
          </MapContainer>
        </div>
        <div class="ml-[20px]">
          <MapContainer :class="'text-[8px]'" title="Etiologia">
            <ul>
              <li>- Dieta inadequada</li>
              <li>- Aumento da demanda</li>
              <li>- Má absorção de ferro</li>
              <li>- Perda sanguinea</li>
            </ul>
          </MapContainer>
        </div>
      </div>

      <div class="absolute top-[300px] z-10 left-[1100px] flex flex-col">
        <svg class="absolute w-[500px] h-[800px] z-0 top-[-20px]">
          <line x1="100" y1="0" x2="100" y2="650" stroke="black" />
          <line x1="50" y1="265" x2="400" y2="265" stroke="black" />
          <line x1="50" y1="650" x2="400" y2="650" stroke="black" />
        </svg>
        <div class="w-[200px] z-10">
          <img src="@/assets/mapa-anemias-carenciais/ciclo.png" alt="" />
        </div>
        <div class="z-10 ml-[30px]">
          <MapButton :class="'w-[100px] text-[10px] h-[25px] py-1 font-bold mt-6 ml-6'"
            >Para realizar</MapButton
          >
        </div>
        <div class="flex w-[600px] items-center z-10">
          <div class="w-[200px] mt-4">
            <MapContainer :class="'text-[8px] p-1'" title="Diagnóstico">
              <ul>
                <li>- Hemograma;</li>
                <li>- Contagem de reticulócitos;</li>
                <li>- Anticorpos anti-células parietais, anti-FI (anemia perniciosa.);</li>
                <li>- Dosagem de vitaminas homocisteína, LDH, Billurrunas;</li>
                <li>- Teste de schilling (absorção B12) - Endoscopia gástrica com biópsia.</li>
              </ul>
            </MapContainer>
          </div>
          <div class="z-10 mr-6">
            <MapButton :class="'text-[10px] h-[25px] py-1 font-bold mt-[10px] ml-[20px]'"
              >Orientação médica</MapButton
            >
          </div>
          <div class="w-[200px] mt-4">
            <MapContainer :class="'text-[8px] p-1'" title="Tratamento">
              <ul>
                <li>- Orientação nutricional.</li>
                <li>
                  - Reposição vitaminica Administração oral ou parenteral de compostos B12 e B9.
                </li>
              </ul>
            </MapContainer>
          </div>
        </div>
        <div class="z-10 ml-[30px]">
          <MapButton :class="' text-[10px] h-[25px] py-1 font-bold mt-6 ml-6'"
            >Observamos</MapButton
          >
        </div>
        <div class="w-[200px] mt-4">
          <MapContainer :class="'text-[8px] p-1'" title="Achados laboratoriais">
            <ul>
              <li>- Hemácias microcíticas e hipocrômicas (VCM, HCM e CHCM diminuídos);</li>
              <li>- Redução do número de hemácias;</li>
              <li>- Anisocitose (RDW elevado);</li>
              <li>- Redução dp número de hemácias;</li>
              <li>
                Citopenias:
                <ul class="ml-2">
                  <li>- Neutropenia;</li>
                  <li>- Plaquetopenia;</li>
                </ul>
              </li>
              <li>- Presença de neutrófilos hipersegmentados;</li>
              <li>- Reticulócitos diminuidos;</li>
            </ul>
          </MapContainer>
        </div>

        <div class="w-[150px] ml-[25px] mt-[10px] z-10">
          <img src="@/assets/mapa-anemias-carenciais/microscopio.jpg" alt="" />
        </div>
      </div>

      <div class="absolute top-[740px] z-10 left-[1330px] flex flex-col">
        <svg class="absolute w-[600px] h-[800px] z-0 top-[-20px]">
          <line x1="40" y1="30" x2="40" y2="200" stroke="black" />
          <line x1="50" y1="70" x2="540" y2="70" stroke="black" />
        </svg>
        <div class="flex mb-4">
          <div class="w-[60px] mr-4 z-10">
            <img src="@/assets/lamina-verm.png" alt="" />
          </div>

          <!-- <div class="w-[150px] mr-4 z-10 mt-[-45px]">
            <span class="text-red-900 font-bold text-sm">Hemacias normocíticas normocrômicas</span>
            <img src="@/assets/mapa-anemias-carenciais/lamina-verm-achados.png" alt="" />
          </div> -->

          <MapContainer
            class="text-[8px] mt-[-45px] w-[200px] mr-4"
            :body_class="'px-1 mx-0 py-1 my-0'"
            :title="'Hemácias macrocíticas normocrômicas'"
          >
            <img src="@/assets/mapa-anemias-carenciais/lamina-verm-achados.png" alt="" />
          </MapContainer>
          <MapContainer
            class="text-[8px] mt-[-45px] w-[200px] mr-4"
            :body_class="'px-1 mx-0 py-1 my-0'"
            :title="'Anel de carbot'"
          >
            <img src="@/assets/mapa-anemias-carenciais/img11.png" alt="" />
          </MapContainer>
          <MapContainer
            class="text-[8px] mt-[-45px] w-[200px]"
            :body_class="'px-1 mx-0 py-1 my-0'"
            :title="'Neutrófilo hipersegmentado'"
          >
            <img src="@/assets/mapa-anemias-carenciais/img12.jpeg" alt="" />
          </MapContainer>

          <!-- <div class="w-[150px] z-10 mr-4 mt-[-35px]">
            <span class="text-red-900 font-bold text-sm">Anel de carbot</span>
            <img src="@/assets/mapa-anemias-carenciais/img11.png" alt="" />
          </div>
          <div class="w-[215px] z-10 mt-[-35px]">
            <span class="text-red-900 font-bold text-sm">Neutrófilo hipersegmentado</span>
            <img src="@/assets/mapa-anemias-carenciais/img12.jpeg" alt="" />
          </div> -->
        </div>
        <div class="flex">
          <div class="w-[60px] mr-4 z-10">
            <img src="@/assets/lamina-azul.png" alt="" />
          </div>
          <!-- <div class="w-[150px] z-10">
            <img src="@/assets/mapa-anemias-carenciais/img9.png" alt="" />
            <span class="text-red-900 font-bold text-sm">Reticulócitos</span>
          </div> -->
          <MapContainer
            class="text-[8px] mt-[-20px] w-[200px] mr-4 z-10"
            :body_class="'px-1 mx-0 py-1 my-0'"
            :title="'Reticulócitos'"
          >
            <img src="@/assets/mapa-anemias-carenciais/img9.png" alt="" />
          </MapContainer>
        </div>
      </div>

      <div class="absolute top-[305px] left-[465px] flex items-center">
        <svg class="absolute w-[500px] h-[500px] z-0 top-[0px]">
          <line x1="10" y1="0" x2="10" y2="100" stroke="black" />
          <line x1="10" y1="72" x2="250" y2="72" stroke="black" />
          <line x1="10" y1="205" x2="500" y2="205" stroke="black" />
          <line x1="500" y1="205" x2="500" y2="300" stroke="black" />
        </svg>
        <div class="z-10 mr-6">
          <MapButton
            :class="'max-w-[100px] text-[10px] h-[25px] py-1 font-bold mt-[15px] ml-[20px]'"
            >Principais</MapButton
          >
        </div>
        <div class="w-[170px]">
          <MapContainer :class="'text-[8px]'" title=" Achados clínicos">
            <ul>
              <li>- Palidez cutaneomucosa;</li>
              <li>- Dispneia;</li>
              <li>- Fadiga;</li>
              <li>- Perversão alimentar ex.: gelo, terra</li>
              <li>- Glossite</li>
              <li>- Entre outras</li>
            </ul>
          </MapContainer>
        </div>
      </div>
      <div class="absolute top-[600px] left-[900px] flex items-center">
        <!-- <span class="w-[150px] h-[200px] bg-gray-100">img 5</span> -->
        <div class="w-[120px] z-10">
          <img src="@/assets/mapa-anemias-carenciais/fe.png" alt="" />
        </div>
      </div>
      <div class="absolute top-[400px] left-[370px] flex items-center">
        <div class="w-[200px]">
          <MapContainer :class="'text-[8px]'" title="Diagnóstico">
            <ul>
              <li>- Investigação de doença de base associada à anemia.</li>
              <li>- Hemograma;</li>
              <li>- Contagem de reticulócitos (diminuído);</li>
              <li>- Cinértica do ferro: Ferro; Ferritina; CTLF;</li>
              <li>
                IST *OBS.: Os níveis podem estar normais ou diminuídos de acordo com a gravidade da
                anemia.
              </li>
              <li>- Exames complementares: Ferro medular; Exames de imagem e endoscópicos</li>
            </ul>
          </MapContainer>
        </div>
        <div class="z-10 mx-[100px]">
          <MapButton :class="'text-[10px] h-[25px] py-1 font-bold mt-6'"
            >Orientação médica</MapButton
          >
        </div>
        <div class="w-[150px] mt-[40px]">
          <MapContainer :class="'text-[8px]'" title="Tratamento">
            <ul>
              <li>- Orientação nutricional.</li>
              <li>- Administração oral ou parenteral de compostos de ferro.</li>
              <li>- Sulfato ferroso, hidróxido de ferro.</li>
            </ul>
          </MapContainer>
        </div>
      </div>
    </div>
  </div>
</template>
