import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueSidebarMenu from 'vue-sidebar-menu';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
import './app.css';
import Vue3Lottie from 'vue3-lottie';

createApp(App)
  .use(store)
  // .use(Vue3Lottie, { name: 'LottieAnimation' })
  .use(router)
  .use(VueSidebarMenu)
  .mount('#app');
