<script setup>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { VideoCameraIcon } from '@heroicons/vue/24/solid';
import MapButton from '@/components/Button/MapButton.vue';
import MapContainer from '@/components/MapContainer/MapContainer.vue';
// import StylizedMapContainer from "@/components/MapContainer/StylizedMapContainer.vue";

import LargeModal from '@/components/Modal/LargeModal.vue';

const store = useStore();

onMounted(() => {
  store.commit({ type: 'changeMapTitle', title: 'Mapa Geral Anemia' });
});
</script>

<template>
  <div class="flex flex-col place-content-start pl-[150px] pt-[150px] pr-[150px] min-h-[1300px]">
    <div class="flex flex-row items-center max-h-[136px]">
      <div class="flex flex-col items-center">
        <MapContainer :body_class="'text-sm mt-12 text-red-900'" :class="'max-w-[400px]'">
          <ul>
            <li>Palidez cutâneo-mucosa.</li>
            <li>Fadiga</li>
            <li>Díspneia</li>
            <li>Cefaléia</li>
            <li>Tontura</li>
            <li>Insônia</li>
            <li>Taquicardia</li>
            <li>Depresão</li>
          </ul>
        </MapContainer>
      </div>

      <svg style="height: 50px; margin-top: -50px; display: flex; width: 100px">
        <line x1="0" y1="50" x2="150" y2="50" stroke="black" />
      </svg>

      <div>
        <MapButton :class="'max-w-[200px] text-lg'">Clínica</MapButton>
      </div>

      <svg style="height: 50px; margin-top: -50px; display: flex; width: 100px">
        <line x1="0" y1="50" x2="150" y2="50" stroke="black" />
      </svg>

      <div
        class="flex flex-col self-start items-center"
        style="margin-left: -450px; margin-right: -450px"
      >
        <MapContainer :body_class="'text-sm text-red-900'" :class="'max-w-[400px]'">
          <p class="text-lg text-center">ANEMIA</p>
          <ul>
            <li>Diminuição da concentração da hemoglobina no sangue.</li>
            <li>Homens: &#8249;13g;dL</li>
            <li>Mulheres: &#8249;12g;dL</li>
            <li>Crianças (6-12 anos): &#8249;11g;dL</li>
            <li>Gestantes: &#8249;11g;dL</li>
          </ul>
          <p class="text-xs text-right">FONTE: OMS.2011</p>
        </MapContainer>

        <svg style="display: flex; height: 50px">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>

        <div class="flex flex-row" style="margin-right: -330px">
          <div class="flex flex-col items-center" style="margin-right: -250px">
            <div style="margin-right: -5px">
              <MapContainer
                :body_class="'text-white text-lg text-white'"
                :class="'max-w-[280px]'"
                :bg_color="'bg-red-800'"
              >
                Fisiopatológica
              </MapContainer>

              <svg style="display: flex; height: 20px; margin-left: -170px">
                <line x1="260" y1="0" x2="260" y2="80" stroke="black" />
              </svg>
              <MapButton :class="'max-w-[200px] text-sm'" style="margin-left: 50px">
                Médula
              </MapButton>
            </div>

            <div class="flex flex-row">
              <div class="flex flex-col items-end" style="margin-right: -220px">
                <svg style="display: flex; height: 15px">
                  <line x1="300" y1="0" x2="300" y2="80" stroke="black" />
                </svg>
                <svg style="height: 10px; display: flex; width: 40px">
                  <line x1="0" y1="0" x2="50" y2="0" stroke="black" />
                </svg>
                <svg style="display: flex; height: 15px; margin-top: -10px; margin-left: -25px">
                  <line x1="260" y1="0" x2="260" y2="80" stroke="black" />
                </svg>

                <MapButton :class="'max-w-[200px] text-sm'"> Hipoproliferativas </MapButton>
                <svg style="display: flex; height: 20px">
                  <line x1="260" y1="0" x2="260" y2="80" stroke="black" />
                </svg>
                <!-- <MapButton :class="'max-w-[200px] text-sm'"> Diminuição </MapButton> -->
                <!-- <svg style="display: flex; height: 20px">
                  <line x1="260" y1="0" x2="260" y2="80" stroke="black" />
                </svg> -->
                <!-- <MapButton :class="'max-w-[200px] text-sm'"> Reticulócitos </MapButton>
                <svg style="display: flex; height: 20px">
                  <line x1="260" y1="0" x2="260" y2="80" stroke="black" />
                </svg> -->
                <!-- <MapButton :class="'max-w-[200px] text-sm'"> Hipoproliferativas </MapButton>
                <svg style="display: flex; height: 20px">
                  <line x1="260" y1="0" x2="260" y2="80" stroke="black" />
                </svg> -->
                <MapButton :class="'max-w-[200px] text-sm'">
                  Reticulócitos produção diminuída
                </MapButton>
                <svg style="display: flex; height: 20px">
                  <line x1="260" y1="0" x2="260" y2="80" stroke="black" />
                </svg>
                <MapButton :class="'max-w-[200px] text-sm'"> Ex: Anemia ferropriva </MapButton>
                <!-- <MapButton :class="'max-w-[200px] text-sm'">
                  Produção de eritrócitos Ex: Anemia ferropriva
                </MapButton> -->
              </div>

              <svg style="display: flex; height: 330px">
                <line x1="245" y1="0" x2="245" y2="430" stroke="black" />
              </svg>
              <div
                class="flex flex-col items-center"
                style="margin-top: 330px; margin-left: -150px"
              >
                <!-- <MapButton :class="'max-w-[200px] text-sm'"> Ciclo de vida eritrócitos </MapButton> -->
                <div :class="'w-[175px] '"></div>
                <!-- <svg style="display: flex; height: 25px; width: 10px">
                  <line x1="0" y1="0" x2="0" y2="50" stroke="black" />
                </svg> -->
                <div>
                  <img
                    class="max-h-[110px] ml-1"
                    src="@/assets/mapa-geral-de-anemias/imagens/ciclo_eri.png"
                    alt=""
                  />
                </div>
              </div>

              <div class="flex flex-col items-start" style="margin-left: -60px">
                <svg style="display: flex; height: 15px">
                  <line x1="0" y1="0" x2="0" y2="80" stroke="black" />
                </svg>
                <svg style="height: 10px; display: flex; width: 40px">
                  <line x1="0" y1="0" x2="50" y2="0" stroke="black" />
                </svg>
                <svg style="display: flex; height: 15px; margin-top: -10px; margin-left: -25px">
                  <line x1="65" y1="0" x2="65" y2="80" stroke="black" />
                </svg>

                <MapButton :class="'max-w-[200px] text-sm'"> Hiperproliferativas </MapButton>
                <!-- <svg style="display: flex; height: 20px">
                  <line x1="45" y1="0" x2="45" y2="80" stroke="black" />
                </svg>
                <MapButton :class="'max-w-[200px] text-sm'"> Aumento </MapButton>
                <svg style="display: flex; height: 20px">
                  <line x1="45" y1="0" x2="45" y2="80" stroke="black" />
                </svg>
                <MapButton :class="'max-w-[200px] text-sm'"> Reticulócitos </MapButton> -->
                <svg style="display: flex; height: 20px">
                  <line x1="45" y1="0" x2="45" y2="80" stroke="black" />
                </svg>
                <!-- <MapButton :class="'max-w-[200px] text-sm'"> Hiperproliferativas </MapButton> -->
                <MapButton :class="'max-w-[200px] text-sm'">
                  Reticulócitos produção aumentada
                </MapButton>
                <svg style="display: flex; height: 20px">
                  <line x1="45" y1="0" x2="45" y2="80" stroke="black" />
                </svg>
                <!-- <MapButton :class="'max-w-[200px] text-sm'">
                  Destruição de hemácias > Produção Ex: Anemias hemolíticas
                </MapButton> -->
                <MapButton :class="'max-w-[200px] text-sm'"> Ex: Anemias hemolíticas </MapButton>
              </div>
            </div>
          </div>

          <svg style="height: 80px; margin-top: -50px; display: flex; width: 150px">
            <line x1="0" y1="80" x2="150" y2="80" stroke="black" />
          </svg>

          <div class="flex flex-col">
            <MapContainer
              :body_class="'text-lg text-white'"
              :class="'max-w-[280px]'"
              :bg_color="'bg-red-800'"
            >
              Classificação
            </MapContainer>
          </div>

          <svg style="height: 80px; margin-top: -50px; display: flex; width: 300px">
            <line x1="0" y1="80" x2="300" y2="80" stroke="black" />
          </svg>

          <div class="flex flex-col items-center" style="margin-left: -400px">
            <MapContainer
              :body_class="'text-lg text-white'"
              :class="'max-w-[280px]'"
              :bg_color="'bg-red-800'"
            >
              Morfológica
            </MapContainer>

            <svg style="display: flex; height: 20px">
              <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
            </svg>
            <MapButton :class="'max-w-[200px]'"> Sangue periférico </MapButton>

            <div class="flex flex-row">
              <div class="flex flex-col items-center">
                <svg style="display: flex; height: 15px">
                  <line x1="249" y1="0" x2="249" y2="80" stroke="black" />
                </svg>
                <svg style="height: 10px; display: flex; width: 202px">
                  <line x1="100" y1="0" x2="200" y2="0" stroke="black" />
                </svg>
                <svg style="display: flex; height: 15px; margin-top: -10px; margin-left: -25px">
                  <line x1="162" y1="0" x2="162" y2="80" stroke="black" />
                </svg>

                <MapContainer
                  :body_class="'text-red-900'"
                  :class="'max-w-[250px]'"
                  :title="'Hemograma'"
                >
                  <p class="text-center">
                    VCM (fL) <br />
                    Microcítica: &#8249;80 <br />
                    Normocítica: 80 - 100 <br />
                    Macrocítica: &#8250;100
                  </p>
                </MapContainer>
                <svg style="display: flex; height: 50px">
                  <line x1="150" y1="0" x2="150" y2="50" stroke="black" />
                </svg>
                <svg style="display: flex; width: 200px; height: 10px">
                  <line x1="200" y1="0" x2="100" y2="0" stroke="black" />
                </svg>
              </div>

              <div class="flex flex-col items-center">
                <svg style="display: flex; height: 15px">
                  <line x1="50" y1="0" x2="50" y2="80" stroke="black" />
                </svg>
                <svg style="height: 10px; display: flex; width: 202px">
                  <line x1="0" y1="0" x2="100" y2="0" stroke="black" />
                </svg>
                <svg style="display: flex; height: 15px; margin-top: -10px; margin-left: -25px">
                  <line x1="162" y1="0" x2="162" y2="80" stroke="black" />
                </svg>

                <MapContainer
                  :body_class="' text-red-900'"
                  :class="'max-w-[250px]'"
                  :title="'Hemograma'"
                >
                  <p class="text-center">
                    HCM (pg) <br />
                    Hipocrômica: &#8249;27 <br />
                    Normocrômica: 27 - 33 <br />
                    CHCM: VR: 31 - 36
                  </p>
                </MapContainer>
                <svg style="display: flex; height: 50px">
                  <line x1="150" y1="0" x2="150" y2="50" stroke="black" />
                </svg>
                <svg style="display: flex; width: 200px; height: 10px">
                  <line x1="100" y1="0" x2="0" y2="0" stroke="black" />
                </svg>
              </div>
            </div>

            <div class="flex flex-col items-center" style="margin-top: -40px">
              <MapContainer
                :body_class="'text-lg text-white '"
                :class="'max-w-[280px]'"
                :bg_color="'bg-red-800'"
              >
                Hemácias
              </MapContainer>

              <svg style="display: flex; height: 30px">
                <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
              </svg>

              <div class="flex flex-row">
                <MapContainer
                  :body_class="' text-red-900'"
                  :class="'w-[270px]'"
                  :title="'Microcíticas Hipocrômicas'"
                >
                  <p class="text-center">Exemplos:</p>
                  <ul>
                    <li>- Deficiência de ferro</li>
                    <li>- Anemia doença crônica</li>
                    <li>- Talassemia</li>
                  </ul>
                </MapContainer>

                <svg style="display: flex; width: 75px">
                  <line x1="0" y1="120" x2="75" y2="120" stroke="black" />
                </svg>

                <MapContainer
                  :body_class="' text-red-900'"
                  :class="'w-[270px]'"
                  :title="'Normocíticas Normocrômicas'"
                >
                  <p class="text-center">Exemplos:</p>
                  <ul>
                    <li>- Anemias Hemolíticas</li>
                    <li>- Nefropatia</li>
                    <li>- Insuficiência MO</li>
                  </ul>
                </MapContainer>

                <svg style="display: flex; width: 75px">
                  <line x1="0" y1="120" x2="75" y2="120" stroke="black" />
                </svg>

                <MapContainer
                  :body_class="' text-red-900'"
                  :class="'max-w-[320px]'"
                  :title="'Macrocíticas Normocrômicas'"
                >
                  <p class="text-center">Exemplos:</p>
                  <ul>
                    <li>- Deficiência de Fol e Vit. B12</li>
                    <li>- Mielodisplasia</li>
                    <li>- Hepatopatia</li>
                  </ul>
                </MapContainer>
              </div>
            </div>
          </div>
        </div>
      </div>

      <svg style="height: 50px; margin-top: -50px; display: flex; width: 500px">
        <line x1="-200" y1="50" x2="500" y2="50" stroke="black" />
      </svg>

      <div style="margin-left: -100px">
        <MapButton :class="'max-w-[200px] text-lg'">Diagnóstico</MapButton>
      </div>

      <svg style="height: 50px; margin-top: -50px; display: flex; width: 350px">
        <line x1="0" y1="50" x2="350" y2="50" stroke="black" />
      </svg>

      <div
        class="flex flex-col self-start items-center mt-12"
        style="margin-left: -100px; margin-right: -85px"
      >
        <MapButton :class="'max-w-[200px] text-lg'">Laboratorial</MapButton>

        <svg style="display: flex; height: 50px">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>

        <MapButton :class="'max-w-[200px] text-lg'">Hemograma</MapButton>

        <svg style="display: flex; height: 50px">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>

        <MapButton :class="'max-w-[200px] text-lg'">Observa-se</MapButton>

        <svg style="display: flex; height: 50px">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>

        <div style="margin-right: -160px">
          <LargeModal>
            <template #button>
              <div class="flex flex-row items-center relative cursor-pointer">
                <MapContainer
                  :body_class="'text-lg text-white'"
                  :class="'max-w-[220px] h-fit'"
                  :bg_color="'bg-red-800'"
                >
                  Hemácias, Microcíticas e Hipocrômicas
                </MapContainer>
                <img
                  class="max-h-[115px] ml-1"
                  src="@/assets/mapa-geral-de-anemias/imagens/hemacias_microciticas_hipocromicas.png"
                  alt=""
                />
                <div class="w-[30px] absolute right-[-10px] top-[-10px]">
                  <VideoCameraIcon class="text-red-900" />
                </div>
              </div>
            </template>

            <template #body>
              <div class="flex justify-center p-4 sm:w-96 md:w-[600px] md:h-[300px]">
                <iframe
                  src="https://player.vimeo.com/video/420764823?h=4efa73c0e9&amp;app_id=122963"
                  width="100%"
                  frameborder="0"
                  allow="autoplay; fullscreen;
              picture-in-picture"
                  allowfullscreen
                  title="Lamina 1 Curso citologia"
                ></iframe>
              </div>
            </template>
          </LargeModal>
        </div>

        <svg style="display: flex; height: 50px">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>

        <div style="margin-right: -160px">
          <LargeModal>
            <template #button>
              <div class="flex flex-row items-center place-content-center cursor-pointer relative">
                <MapContainer
                  :body_class="'text-lg text-white'"
                  :class="'max-w-[220px] h-fit'"
                  :bg_color="'bg-red-800'"
                >
                  Hemácias, Normocíticas e Normocrômicas
                </MapContainer>
                <img
                  class="max-h-[115px] ml-1"
                  src="@/assets/mapa-geral-de-anemias/imagens/hemacias_normocitica_e_normocromica.png"
                  alt=""
                />
                <div class="w-[30px] absolute right-[-10px] top-[-10px]">
                  <VideoCameraIcon class="text-red-900" />
                </div>
              </div>
            </template>

            <template #body>
              <div class="flex justify-center p-4 sm:w-96 md:w-[600px] md:h-[300px]">
                <iframe
                  src="https://player.vimeo.com/video/420775891?h=196c03950d&amp;app_id=122963"
                  width="100%"
                  frameborder="0"
                  allow="autoplay; fullscreen;
              picture-in-picture"
                  allowfullscreen
                  title="Lamina 6 curso de citologia"
                ></iframe>
              </div>
            </template>
          </LargeModal>
        </div>

        <svg style="display: flex; height: 50px">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>

        <div style="margin-right: -160px">
          <LargeModal>
            <template #button>
              <div class="flex flex-row items-center place-content-center cursor-pointer relative">
                <MapContainer
                  :body_class="'text-lg text-white'"
                  :class="'max-w-[220px] h-fit'"
                  :bg_color="'bg-red-800'"
                >
                  Hemácias, Macrocíticas e Normocrômicas
                </MapContainer>
                <img
                  class="max-h-[115px] ml-1"
                  src="@/assets/mapa-geral-de-anemias/imagens/hemacias_macrociticas_e_normocromicas.png"
                  alt=""
                />
                <div class="w-[30px] absolute right-[-10px] top-[-10px]">
                  <VideoCameraIcon class="text-red-900" />
                </div>
              </div>
            </template>

            <template #body>
              <div class="flex justify-center p-4 sm:w-96 md:w-[600px] md:h-[300px]">
                <iframe
                  src="https://player.vimeo.com/video/420776400?h=9800cf0dad&amp;app_id=122963"
                  width="100%"
                  height="auto"
                  frameborder="0"
                  allow="autoplay; fullscreen;
              picture-in-picture"
                  allowfullscreen
                  title="Lamina 8 curso citologia"
                ></iframe>
              </div>
            </template>
          </LargeModal>
        </div>
      </div>

      <svg style="height: 50px; margin-top: -50px; display: flex; width: 400px">
        <line x1="0" y1="50" x2="400" y2="50" stroke="black" />
      </svg>

      <div
        class="flex flex-col self-start items-center mt-8"
        style="margin-right: -220px; margin-left: -220px"
      >
        <div>
          <MapButton :class="'max-w-[200px] text-xl'">Contagem Reticulócitos</MapButton>
        </div>

        <svg style="display: flex; height: 50px">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>

        <div>
          <MapButton :class="'max-w-[200px] flex flex-col items-center'">
            <p>Valor relativo = Reticulócitos (em 1000 hemácias)</p>
            <svg style="display: flex; height: 5px; width: 150px">
              <line x1="0" y1="0" x2="150" y2="0" stroke="black" />
            </svg>
            <p>10</p>
          </MapButton>
        </div>

        <svg style="display: flex; height: 50px">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>

        <div>
          <MapButton :class="'max-w-[200px] flex flex-col items-center'">
            <p>Valor absoluto = Hemácias (milhões) x Reticolócitos</p>
            <svg style="display: flex; height: 5px; width: 150px">
              <line x1="0" y1="0" x2="150" y2="0" stroke="black" />
            </svg>
            <p>(%) 100</p>
          </MapButton>
        </div>

        <svg style="display: flex; height: 25px">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>
        <svg style="height: 80px; margin-top: -50px; display: flex; width: 200px">
          <line x1="0" y1="50" x2="350" y2="50" stroke="black" />
        </svg>
        <svg style="display: flex; margin-top: -30px; height: 25px; width: 350px">
          <line x1="75" y1="0" x2="75" y2="80" stroke="black" />
        </svg>
        <svg style="display: flex; margin-top: -30px; height: 25px; width: 350px">
          <line x1="275" y1="5" x2="275" y2="25" stroke="black" />
        </svg>

        <div class="flex flex-row">
          <div class="flex flex-col items-center" style="margin-right: -75px">
            <div>
              <MapContainer
                :body_class="'text-lg text-white'"
                :class="'max-w-[280px]'"
                :bg_color="'bg-red-800'"
              >
                Aumento
              </MapContainer>
            </div>

            <svg style="display: flex; height: 50px">
              <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
            </svg>

            <div>
              <MapButton :class="'max-w-[200px]'">
                Indica atividade proliferativa compensatória da M.0.
              </MapButton>
            </div>
          </div>
          <div class="flex flex-col items-center">
            <div>
              <MapContainer
                :body_class="'text-lg text-white'"
                :class="'max-w-[280px]'"
                :bg_color="'bg-red-800'"
              >
                Diminuição
              </MapContainer>
            </div>

            <svg style="display: flex; height: 50px">
              <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
            </svg>

            <div>
              <MapButton :class="'max-w-[200px]'"> Indica uma M.0. Hipoproliferativa </MapButton>
            </div>
          </div>
        </div>
      </div>

      <svg style="height: 50px; margin-top: -50px; display: flex; width: 150px">
        <line x1="60" y1="50" x2="150" y2="50" stroke="black" />
      </svg>

      <div>
        <MapButton :class="'max-w-[280px] text-xl'"> Complementares </MapButton>
      </div>

      <svg
        style="height: 50px; margin-top: -50px; margin-right: -30px; display: flex; width: 100px"
      >
        <line x1="0" y1="50" x2="150" y2="50" stroke="black" />
      </svg>

      <div class="flex flex-col self-start items-center -ml-12">
        <div>
          <MapContainer :body_class="'text-red-900 text-sm'" :class="'max-w-[280px]'">
            <ul>
              <li>-Cinética do ferro</li>
              <li>-Eletroforese HB</li>
              <li>-VIT. B12 e Folatos</li>
              <li>-Pesquisa de sangue oculto nas fezes</li>
            </ul>
          </MapContainer>
        </div>

        <svg style="display: flex; height: 50px">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>

        <div>
          <MapContainer :body_class="'text-red-900'" :class="'max-w-[280px] text-sm'">
            Análise crítica da resultados com a avaliação clínica
          </MapContainer>
        </div>

        <svg style="display: flex; height: 50px">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>

        <div>
          <MapContainer
            :body_class="'text-lg text-white'"
            :class="'max-w-[280px]'"
            :bg_color="'bg-red-800'"
          >
            Tratamento
          </MapContainer>
        </div>

        <svg style="display: flex; height: 50px">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>

        <div>
          <MapContainer :body_class="'text-red-900'" :class="'max-w-[280px] text-sm'">
            Principais
          </MapContainer>
        </div>

        <svg style="display: flex; height: 50px">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>

        <div>
          <MapContainer :body_class="'text-sm text-red-900'" :class="'max-w-[400px]'">
            <p class="text-lg text-center font-bold">Anemias carências</p>
            <ul class="mb-2">
              <li>- Suplementos vitaminícos (Ex: B12 e Folato)</li>
              <li>- Suplemento de ferro oral/parenteral (Deficiência de ferro)</li>
              <li>- Plano nutricional saudável (Dieta rica em ferro, B12 e folato)</li>
            </ul>

            <p class="text-lg text-center font-bold">Anemias hemolíticas</p>
            <p class="text-md text-justify-left mb-2">
              Imunossupressores (Predisona, Rituximab anti-CD20) Esplenectomia AHAI a quente (IgG -
              Hemolise extravascular: Baço)
            </p>

            <p class="text-lg text-center font-bold">Hemoglobinopatias</p>
            <p class="text-md text-justify-center">
              Uso terapêutico do sangue, Suplementos vitamínicos, Suplemento de ferro
              oral/parenteral, Hidroxiuréia, Transplante de médula óssea.
            </p>
          </MapContainer>
        </div>
      </div>
    </div>
  </div>
</template>
