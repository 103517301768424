<script setup>
import { ref } from 'vue';
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { useStore } from 'vuex';

const chevron_left_icon = mdiChevronLeft;
const chevron_right_icon = mdiChevronRight;

const store = useStore();

const menu = [
  {
    header: '',
    hiddenOnCollapse: true,
  },
  {
    href: '/anemias',
    title: 'Mapa Geral de Anemias',
    icon: 'fa fa-user',
  },
  {
    href: '/hemoglobinopatias',
    title: 'Mapa Hemoglobinopatias',
    icon: 'fa fa-user',
  },

  {
    href: '/anemias/hemoliticas',
    title: 'Mapa de Anemias Hemolíticas',
    icon: 'fa fa-user',
  },
  {
    href: '/anemias/carenciais',
    title: 'Mapa de Anemias Carenciais',
    icon: 'fa fa-user',
  },
];
</script>

<template>
  <div class="flex overflow-x-hidden h-screen">
    <aside
      class="flex-shrink-0 flex flex-col border-r transition-all duration-300"
      :class="{ '-ml-64': !store.state.menuOpen }"
    >
      <div class="h-16"></div>
      <nav class="flex-1 flex text-left flex-col bg-[#7F0404] text-white">
        <template v-for="(link, index) in menu" :key="`menu-${index}`">
          <a
            :href="link.href"
            :class="`px-3 py-2 hover:bg-red-800 ${
              store.state.selected_map_title === link.title ? 'bg-red-800' : ''
            }`"
            >{{ link.title }}</a
          >
        </template>
      </nav>
    </aside>
  </div>
</template>

<style>
.v-sidebar-menu {
  background-color: rgb(127, 4, 4) !important;
}

.v-sidebar-menu.vsm_expanded {
  max-width: 320px !important;
}

.menu-header {
  background-color: rgb(127, 4, 4);
  min-height: 5vh;
  max-height: 5vh;
}

.v-sidebar-menu .vsm--toggle-btn {
  background-color: white !important;
}

.v-sidebar-menu .vsm--mobile-bg {
  background-color: #4e1113 !important;
  width: 320px !important;
}

.v-sidebar-menu .vsm--link_level-1.vsm--link_active {
  box-shadow: inset 3px 0 0 0 #4e1113 !important;
}

.vsm--icon {
  background-color: #1e1e21 !important;
}
</style>
